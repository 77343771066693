//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import _ from "lodash";
import cityLinksHelper from "~/util/city-links-helper";
import Container from "~/components/common/Container.vue";
import Arrow from "~/components/icons/Arrow.vue";
import Shimmer from "~/components/common/Shimmer.vue";
import { webpToJpg } from "~/util/helpers";

export default {
    name: "MainBannerSlider",
    components: { Shimmer, Container, Swiper, SwiperSlide, Arrow },
    data() {
        return {
            isSwiperInitialized: false,
            swiperOptions: {
                slidesPerView: "auto",
                loop: false,
                spaceBetween: 8,
                directive: "horizontal",
                speed: 1200,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                navigation: {
                    nextEl: ".swiper-button_next",
                    prevEl: ".swiper-button_prev",
                },
                on: {
                    init: () => {
                        this.isSwiperInitialized = true;
                    },
                },
                breakpoints: {
                    320: {
                        loop: true,
                    },
                    600: { spaceBetween: 16, loop: false },
                },
            },
            swiper: null,
        };
    },
    computed: {
        ...mapState("promo", ["banners", "stories"]),
        ...mapGetters("modals", ["getOpenedModal"]),
        ...mapGetters("promo", ["getStoriesHistory"]),
        isMobile() {
            return this.$screen.width < 801;
        },
        getSwiperElements() {
            const stories = this.stories.map((item) => {
                const elem = this.getStoriesHistory.find((story) => story.id === item.id);
                return {
                    ...item,
                    isStory: true,
                    isChecked: !elem?.isChecked,
                };
            });
            const banners = this.banners.map((item) => {
                return { ...item, isBanner: true };
            });

            return this.interleaveArrays(banners, stories);
        },
    },
    mounted() {
        this.swiper = this.$refs.mainSwiper?.$swiper;

        if (this.getStoriesHistory.length === 0) {
            let historyStories;
            if (!localStorage.getItem("historyStories")) {
                historyStories = this.stories.map((item) => {
                    return { id: item.id, isChecked: false };
                });
                localStorage.setItem("historyStories", JSON.stringify(historyStories));
            } else {
                historyStories = JSON.parse(localStorage.getItem("historyStories"));

                const stories = this.stories.map((item) => {
                    return { id: item.id, isChecked: false };
                });
                const difference = _.differenceBy(stories, historyStories, "id");

                historyStories = [...historyStories, ...difference];
            }

            this.setStoriesHistory(historyStories);
        }
    },
    beforeDestroy() {},
    methods: {
        ...mapActions("modals", ["openModal", "closeModal"]),
        ...mapMutations("promo", ["setStoriesHistory"]),
        jpgPhoto(photo) {
            return webpToJpg(photo);
        },

        interleaveArrays(array1, array2) {
            return array1.reduce((result, item, index) => {
                return result.concat(item, array2[index] || []);
            }, []);
        },
        mouseEnterSlider() {
            if (!this.swiper.destroyed) {
                this.swiper.autoplay.pause();
            }
        },
        mouseLeaveSlider() {
            if (!this.swiper.destroyed) {
                this.swiper.autoplay.run();
            }
        },
        isRelativeUrl(url) {
            return !!url && url.startsWith("/");
        },
        getCityLinkUrl(url) {
            return cityLinksHelper.getNuxtLinkToPath(url, cityLinksHelper.getCityIdFromVuex(this.$store));
        },
        getDestinationUrl(url) {
            if (!url) {
                return url;
            }

            if (this.isRelativeUrl(url)) {
                return this.getCityLinkUrl(url);
            }

            return url;
        },
        goLink(banner) {
            if (!banner.destination_url) {
                return;
            }

            if (this.isRelativeUrl(banner.destination_url)) {
                const url = this.getCityLinkUrl(banner.destination_url);
                this.$router.push(url);

                if (this.$catalogRouter.doesPathExist(cityLinksHelper.cleanCityPath(url))) {
                    this.$nuxt.$emit("scroll-to-path", url);
                }
            } else {
                window.open(banner.destination_url, "_blank").focus();
            }
        },
        openStori(stori) {
            this.openModal({
                modalName: "StoriesSliderModal",
                modalData: { currentStori: stori },
                componentProps: { size: "l", no_bg: true },
            });
        },
    },
};
